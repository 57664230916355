export const Colors = {
  ANTARCTIC: `var(--antarctic)`,
  ATLANTIC: `var(--atlantic)`,
  BALTIC: `var(--baltic)`,
  BATTLESHIP_GREY: `var(--battleshipGrey)`,
  BLUEBERRY: `var(--blueberry)`,
  BLUEISH: `var(--blueish)`,
  BORDER: `var(--defaultBorderColor)`,
  BODYCOPYGREY: 'var(--bodyCopyBrey)',
  BRIGHT_SLATE: `var(--brightSlate)`,
  BRIGHT_TANGERINE: `#ffe5bf`,
  CADBURY: `var(--cadbury)`,
  CHERRY: `var(--cherry)`,
  DANGER: `var(--rojo)`,
  DARK_SLATE: `var(--darkSlate)`,
  DARK_TANGERINE: `var(--darkTangerine)`,
  DAWN: `var(--dawn)`,
  DAY: `var(--day)`,
  DRAGONFRUIT: `var(--dragonfruit)`,
  DUSK: `var(--dusk)`,
  FANAI_BLUE2: `#143962`,
  FANAI_BLUE3: `#4f6b89`,
  GRAPE: `var(--grape)`,
  GRASS: `var(--grass)`,
  GRID_LINES: `var(--gridLines)`,
  GRIMACE: `var(--grimace)`,
  HOVER_ORANGE: '#be5800',
  IVY: 'var(--ivy)',
  KERMIT: '#3d918f',
  LAKE: `var(--lake)`,
  LIGHT_PERIWINKLE: `var(--lightPeriwinkle)`,
  LIGHT_SLATE: `var(--lightSlate)`,
  LIGHT_TANGERINE: `var(--lightTangerine)`,
  LIGHTER_GREY_BLUE: `var(--lighterGreyBlue)`,
  LILAC: `var(--lilac)`,
  LIME: `var(--lime)`,
  MAIN_FONT_COLOR: `var(--mainFontColor)`,
  MARINE: `var(--marine)`,
  MED_SLATE: '#737885',
  MINION_YELLOW: '#ffe141',
  MIDNIGHT: `var(--midnight)`,
  PACIFIC: '#2d86c0',
  PEARL: '#f7f8fa',
  POND: `var(--pond)`,
  PUDDLE: '#f0f2f5',
  ROJO: '#ef2d56',
  SLATE: `#646780`,
  SLATE2: `var(--slate)`,
  SUPERIOR: `var(--superior)`,
  TANGERINE: '#ee6e00',
  TRINIDAD: `var(--trinidad)`,
  TOOLTIP_BOX_SHADOW: `0 4px 8px 0 rgba(0, 0, 0, 0.1)`,
  TOOLTIP_DARK_BACKGROUND: `var(--tooltipDarkBackground)`,
  TRANSPARENT: `transparent`,
  WHITE: 'white'
}

export const Fonts = {
  weight: {
    NORMAL: 'var(--normal)',
    SEMI_BOLD: 'var(--semiBold)',
    BOLD: 'var(--bold)'
  }
}

export const Styles = {
  CIRCLE_RADIUS: `50%`,
  DELAY: 0.4,
  DROPDOWN_DEFAULT_HEIGHT: 300,
  DROPDOWN_DEFAULT_WIDTH: 300,
  DROPDOWN_ROW_HEIGHT: 40,
  FONT_SIZE: `var(--fontSize)`,
  MAIN_FONT_FAMILY: `var(--mainFontFamily)`,
  NORMAL: `var(--normal)`,
  SMALL_COPY: `var(--smallCopy)`,
  SEMI_BOLD: `var(--semiBold)`,
  TILE_PADDING_MOBILE: 16,
  UPLOAD_MODAL_ICON_SIZE: '5rem',
  BUTTON_SPINNER_SIZE: '1.5rem',
  BUTTON_SMALL_SPINNER_SIZE: '1rem'
}

export const ZIndices = {
  TOOLTIP: 900
}

export const CustomMedia = {
  MAX_WIDTH_IPAD_PRO: 1024
}

export const Charts = {
  DASH_STYLE: `shortdash`,
  DATE_TIME_LABEL_FORMATS: {
    day: '%b %e',
    minute: '%H:%M',
    month: '%b %e',
    week: '%b %e',
    year: '%Y'
  },
  ONE_DAY: 24 * 3600 * 1000,
  TOOLTIP_BORDER_RADIUS: 6,
  TOOLTIP_BORDER_WIDTH: 1,
  TOOLTIP_PADDING: 16,
  X_AXIS_CHART_TYPE: `datetime`,
  X_AXIS_END_ON_TICK: true,
  X_AXIS_LINE_WIDTH: 1,
  X_AXIS_TICK_LENGTH: 4,
  X_AXIS_TICK_WIDTH: 1,
  Y_AXIS_MIN: 0
}

export const Forms = {
  BORDER_RADIUS: '4px',
  textInput: {
    FONT: '0.875rem var(--mainFontFamily)',
    HEIGHT: '36px',
    PADDING_X: '0.75rem'
  }
}

export const Spacing = {
  xs: '0.25rem',
  small: '0.5rem',
  medium: '1rem',
  large: '1.5rem',
  xl: '2rem'
}
