import * as React from 'react'
import { Entity } from 'types'

interface GlobalContextInterface {
  contentRef?: any
  brand?: Entity
  brands?: Entity[]
  setBrand?: any
}

const defaultContext: GlobalContextInterface = {
  contentRef: { current: null },
  setBrand: () => {}
}

const GlobalContext = React.createContext(defaultContext)

export default GlobalContext
