import * as React from 'react'
import { User, Features } from 'types'

interface AuthContextInterface {
  user: User | null
  features?: Features
  showCategoryLinks?: boolean
}

const defaultContext: AuthContextInterface = {
  user: null,
  features: null,
  showCategoryLinks: false
}

const AuthContext = React.createContext(defaultContext)

export default AuthContext
