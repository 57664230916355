import StringUtility from 'utils/stringReplacement'

const copy = new StringUtility({
  manageUniverse: {
    name: 'Manage Universe'
  },
  myDashboard: {
    name: 'My Dashboard'
  },
  sponsorshipSupport: {
    name: 'Sponsorship Support'
  },
  fanSpendingByCategory: {
    name: 'Fan Spending by Category',
    tileBody:
      'Investigate how fans spend their money within a specified category.'
  },
  fanSpendingByBrand: {
    name: 'Fan Spending by Brand',
    tileBody:
      'Investigate how fans spend their money within a category related to a specific brand.'
  }
})

export default copy
