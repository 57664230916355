import { track } from 'actions/analyticsActions'
import API from 'config/api'
import { defaultCache } from 'config/appConfig'
import i18nInitialize from 'config/i18n'
import initMocksModule from 'config/mocks'
import { appRoutes } from 'config/routes'
import AuthContext from 'context/authContext'
import Modal from 'deprecated/molecules/modal'
import { AnimatePresence, motion } from 'framer-motion'
import RequestStore from 'helpers/dbSetup'
import { useSelector } from 'hooks'
import { createLogger } from 'logging'
import IdleTimeout from 'molecules/idleTimeout'
import Toaster from 'organisms/toaster'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import axios from 'utils/axios'
import findClosestAncestor from 'utils/findClosestAncestor'
import { disableChartAnimations } from 'utils/highcharts'
import { toPrecision } from 'utils/numberFormatter'
import { loginUserSuccess, logoutUser } from '../../actions/AuthActions'
import errorCopy from '../../copy/errors'
import { AppContainerProps } from './index.d'

const logger = createLogger({ name: 'App' })

if (USE_MOCKS) {
  initMocksModule()
}

smoothscroll.polyfill()

const AuthenticatedJourney = lazy(() =>
  import(/* webpackPreload: true */ 'pages/authenticatedContainer')
)

const UnAuthenticatedJourney = lazy(() =>
  import(/* webpackPreload: true */ 'pages/unAuthenticatedContainer')
)

const WhitePaperLandingPage = lazy(() => import('pages/whitePaperLandingPage'))

const animationConfig = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  transition: {
    delay: 0.3
  }
}

const AppContainer: React.FC<AppContainerProps> = props => {
  const dispatch = useDispatch()
  const [isInitializing, setIsInitializing] = useState<boolean>(true)
  const history = useHistory()
  const user = useSelector(state => state.userState.user)

  let globalMouseClickTimer = null
  let globalMouseClickCount = 0
  let globalMouseMoveTimer = null
  let globalMouseMoveTimerActivated = null
  let globalMouseMoveTimeoutTimer = null

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      try {
        logger.info('starting app')

        const token = localStorage.getItem('token')
        if (token) {
          const userRes = await axios.get(API.currentUser, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })

          const user = { ...userRes.data.data, token }

          dispatch(loginUserSuccess(user))
        }

        // initialize indexDB
        const store = new RequestStore({ ...defaultCache })
        // invalidate any cached records that are older than the configured expiry time
        store.inValidateExpiredEntries()

        // setup global click and mouse events to track analytics and user inactivity
        document.addEventListener('click', handleGlobalMouseClick)
        document.addEventListener('mousemove', handleGlobalMouseMove)

        i18nInitialize()
        setIsInitializing(false)
        return () => {
          document.removeEventListener('click', handleGlobalMouseClick)
          document.removeEventListener('mousemove', handleGlobalMouseMove)
        }
      } catch (error) {
        setIsInitializing(false)
      }
    })()

    // eslint-disable-next-line
  }, [])

  // localStorage.removeItem('token')
  const logout = () => {
    dispatch(logoutUser())
  }

  const expireSession = () => {
    history.push(appRoutes.sessionExpired)
  }

  const handleGlobalMouseMove = e => {
    // if the mouse move event is fired and they were previously
    // inactive for a period between 1 and 5 min then track that
    const min = 1000 * 60
    if (globalMouseMoveTimerActivated) {
      // log analytics event for non-activity
      const timeInactive =
        (Date.now() - globalMouseMoveTimerActivated.time + min) / 1000 / 60

      formAnalyticsEvent(
        globalMouseMoveTimerActivated.event,
        `inactive:${toPrecision(timeInactive, 3)}m`,
        'no_activity'
      )

      globalMouseMoveTimerActivated = null
      clearTimeout(globalMouseMoveTimeoutTimer)
    }

    // clear the timer since the mouse has moved
    clearTimeout(globalMouseMoveTimer)
    // reset the min no movement timer
    globalMouseMoveTimer = setTimeout(() => {
      if (!globalMouseMoveTimerActivated) {
        globalMouseMoveTimerActivated = { time: Date.now(), event: e }
        // set a max timer so that if the mouse hasn't moved in a min
        // and doesn't move after 5 additional min then we assume the
        // user is no longer interacting with the app
        globalMouseMoveTimeoutTimer = setTimeout(() => {
          globalMouseMoveTimerActivated = null
          clearTimeout(globalMouseMoveTimer)
          clearTimeout(globalMouseMoveTimeoutTimer)
        }, min * 5)
      }
    }, min)
  }

  const handleGlobalMouseClick = e => {
    globalMouseClickCount++
    clearTimeout(globalMouseClickTimer)
    clearTimeout(globalMouseMoveTimer)
    // set polling for additional events to handle 'rage clicking'
    // if additional click events happen within a set interval
    // then track the count of those and publish once complete
    globalMouseClickTimer = setTimeout(() => {
      if (globalMouseClickCount > 2) {
        formAnalyticsEvent(
          e,
          `num_clicks: ${globalMouseClickCount}`,
          'rage_click'
        )
      }
      globalMouseClickCount = 0
      clearTimeout(globalMouseClickTimer)
    }, 500)
  }

  const formAnalyticsEvent = (event, label, action) => {
    const target = findClosestAncestor(event.target, '[data-ref]')
    dispatch(
      track({
        eventAction: action,
        eventData: {
          label: `${label}[element]:${(target && target.dataset.ref) ||
            event.target.getAttribute('id') ||
            (event.target.classList.length &&
              [...event.target.classList].join(',')) ||
            'not_found'}`
        },
        timeTravel: {
          after: true,
          before: true
        }
      })
    )
  }

  if (
    window.location.pathname ===
    '/the-most-important-sponsorship-metric-you-have-never-seen'
  ) {
    return (
      <Suspense fallback={null}>
        {!isInitializing && <WhitePaperLandingPage />}
      </Suspense>
    )
  }

  return (
    <>
      {!isInitializing && (
        <Suspense fallback={null}>
          <AnimatePresence>
            {user && user.token ? (
              <AuthContext.Provider value={{ user }}>
                <motion.div {...animationConfig} transition={{ delay: 0.2 }}>
                  <AuthenticatedJourney />
                </motion.div>
              </AuthContext.Provider>
            ) : (
              <motion.div {...animationConfig} style={{ height: '100%' }}>
                <UnAuthenticatedJourney />
              </motion.div>
            )}
          </AnimatePresence>
        </Suspense>
      )}
      {user && user.token && (
        <IdleTimeout>
          {(idle, reset) =>
            idle && (
              <Modal
                title={errorCopy.get('sessionExpiring.title')}
                cancelCallback={reset}
                cancelLabel={errorCopy.get('sessionExpiring.cancelLabel')}
                successCallback={logout}
                successLabel={errorCopy.get('sessionExpiring.successLabel')}
                show={true}
                description={errorCopy.get('sessionExpiring.description')}
              >
                <div>
                  <p>{errorCopy.get('sessionExpiring.body')}</p>
                  {idle && (
                    <IdleTimeout
                      idleCallback={expireSession}
                      timeout={1000 * 60 * 2}
                    />
                  )}
                </div>
              </Modal>
            )
          }
        </IdleTimeout>
      )}
      <Toaster />
    </>
  )
}
export default AppContainer
