const version = process.env.API_VERSION || 0
const hostname = process.env.API_ENDPOINT || ''
const prefix = ''

const endpoints = {
  loginLinks: `https://${hostname}/v${version}/core/login-links`,
  sessions: `https://${hostname}/v${version}/core/sessions`,
  users: `https://${hostname}/v${version}/core/users`,
  accounts: `https://${hostname}/v${version}/core/accounts`,
  brands: `https://${hostname}/v${version}/core/brands`,
  categoryAnalyses: `https://${hostname}/v${version}/core/category-analyses`,
  properties: `https://${hostname}/v${version}/core/properties`,
  propertyCatagories: `https://${hostname}/v${version}/core/property-catagories`,
  propertyAudiences: `https://${hostname}/v${version}/core/property-audiences`,
  audienceConfigs: `https://${hostname}/v${version}/core/audience-configs`,
  seasons: `https://${hostname}/v${version}/core/seasons`,
  regionalityTypes: `https://${hostname}/v${version}/core/regionality-types`,
  insights: `https://${hostname}/v${version}/core/insights`,
  currentUser: `https://${hostname}/v${version}/core/users/current`,

  compare: `${prefix}/v${version}/twitter/compare`,
  clearbit: 'https://logo.clearbit.com',
  historical: `${prefix}/v${version}/twitter/historical_follows`,
  piiAudienceCategories: `${prefix}/v${version}/pii/spend_behavior/categories`,
  piiAudiences: `${prefix}/v${version}/pii/audiences`,
  piiPropertyTrackings: `${prefix}/v${version}/pii/property-trackings`,
  piiSpend: `${prefix}/v${version}/pii/spend`,
  piiSpendBehavior: `${prefix}/v${version}/pii/spend-behaviors`,
  piiSpendUplift: `${prefix}/v${version}/pii/spend-uplift`,
  publicEntities: `${prefix}/v${version}/public-entities/entities`,
  publicEntitiesDefault: `${prefix}/v${version}/public-entities`,
  publicEntitiesBrands: `${prefix}/v${version}/public-entities/brands`,
  publicEntitiesCategories: `${prefix}/v${version}/public-entities/categories`,
  publicEntitiesCollections: `${prefix}/v${version}/public-entities/collections`,
  publicEntitiesCollectionsCategories: `${prefix}/v${version}/public-entities/collections/categories`,
  publicEntitiesExtensionOpportunity: `${prefix}/v${version}/twitter/brands`,
  publicEntitiesGames: `${prefix}/v${version}/public-entities/games`,
  publicEntitiesInfluencers: `${prefix}/v${version}/public-entities/influencers`,
  publicEntitiesLeagues: `${prefix}/v${version}/public-entities/leagues`,
  publicEntitiesOrgs: `${prefix}/v${version}/public-entities/orgs`,
  publicEntitiesRosterOwners: `${prefix}/v${version}/public-entities/roster_owners`,
  publicEntitiesRosters: `${prefix}/v${version}/public-entities/rosters`,
  publicEntitiesSystem: `${prefix}/v${version}/public-entities/system`,
  registrations: `${prefix}/v${version}/auth/registrations`,
  records: `${prefix}/v${version}/pii/records`,
  spendCategories: `${prefix}/v${version}/pii/spend_behavior/categories`,
  spendTypeBins: `${prefix}/v${version}/pii/spend_behavior/general_categories`,
  twitterAdminTracker: `${prefix}/v${version}/twitter/tracking`,
  twitterUser: `${prefix}/v${version}/twitter/twitter_users`,
  universeIds: `${prefix}/v${version}/public-entities/universe/ids`
}

// escape forward slashes
export const toRegex = (path: string): RegExp =>
  new RegExp(path.replace(/\//g, '\\/'))

export default endpoints
