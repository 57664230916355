import errorCodes from 'config/errorCodes'
import {
  confirmPasswordReset,
  getAdminClaim,
  getClientDetails,
  getSecurityGroups,
  requestPasswordReset,
  verifyPasswordReset
} from 'helpers/auth'
import union from 'lodash/union'
import { createLogger } from 'logging'
import { Dispatch } from 'redux'
import { InitialUserObject, SavedEntity } from 'types'
import { analyticsKeys, analyticsTypes } from '../constants/analytics'

const { INIT, PAGE_VIEW } = analyticsTypes

import {
  constants,
  FORM_VALUES_UPDATED,
  INITIALIZE_LOGIN,
  INITIALIZE_PASSWORD_RECOVERY,
  LOGIN_USER_SUCCESS,
  PASSWORD_RESET_COMPLETE,
  PASSWORD_RESET_INVALID_CODE_FAILURE,
  PASSWORD_RESET_REQUEST_COMPLETE,
  REQUEST_COMPLETE,
  REQUEST_IN_PROGRESS,
  STORE_PASSWORD_RESET_EMAIL
} from './types'

import formBreadcrumbs from 'utils/breadcrumbs'

const {
  CLEAR_ERROR,
  SET_PARENT_ENTITY,
  TOGGLE_GLOBAL_SIDEBAR,
  TOGGLE_GLOBAL_FILTER_PANE,
  SET_DASHBOARD_HAS_FILTER_PANE,
  UNIVERSE_COMPONENT_PATHS_UPDATED
} = constants

import {
  ANALYTICS,
  SET_ENTITY_UNIVERSE,
  USER_PERMISSIONS_RECEIVED
} from '../constants'

const logger = createLogger({ name: 'actions/index' })

/**
 * Make reset state for password reset flag
 *
 * @export  - initializeLogin
 * @returns - undefined
 */
export const initializeLogin = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: INITIALIZE_LOGIN
  })

/**
 * Make reset state for password reset flag
 *
 * @export  - initializeLogin
 * @returns - undefined
 */
export const initializePasswordRecovery = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: INITIALIZE_PASSWORD_RECOVERY
  })

/**
 * Make request to reset the given user's password
 *
 * @export               - requestPasswordReset
 * @param {string} email - given email address
 * @returns              - function
 */
export const requestUserPasswordReset = (email: string, cb?: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      payload: {},
      type: REQUEST_IN_PROGRESS
    })

    try {
      await requestPasswordReset(email)
      // tslint:disable-next-line
      cb && cb()
    } catch (e) {
      // invalid email address format or email not found fail silently
    }

    dispatch({
      type: PASSWORD_RESET_REQUEST_COMPLETE
    })

    dispatch({
      type: REQUEST_COMPLETE
    })
  }
}

/**
 * Make request with auth codes from sent email and reset the user's password with the given one provided
 *
 * @export                  - resetUserPassword
 * @param {string} oobCode  - used to verify the reset link is still valid
 * @param {string} password - new password
 * @returns                 - function
 */
export const resetUserPassword = (oobCode: string, password: string) => {
  return (dispatch: Dispatch<any>) => {
    const errorHandler = handleError(
      dispatch,
      errorCodes
    )(PASSWORD_RESET_INVALID_CODE_FAILURE)

    dispatch({
      payload: {},
      type: REQUEST_IN_PROGRESS
    })

    return new Promise(resolve => {
      verifyPasswordReset(oobCode)
        .then(email => {
          dispatch({
            email,
            type: STORE_PASSWORD_RESET_EMAIL
          })

          confirmPasswordReset(oobCode, password)
            .then(() => dispatch({ type: PASSWORD_RESET_COMPLETE }))
            .then(() => {
              dispatch({
                type: REQUEST_COMPLETE
              })
              resolve(true)
            })
            .catch(errorHandler)
        })
        .catch(errorHandler)
    })
  }
}

/**
 * Updates the for values for the specified form in the application's state
 *
 * @export                - updateFormValues
 * @param {object} values - user defined form values
 * @param {string} form   - specified form to update
 * @returns               - function
 */
export const updateFormValues = (values: { type; value }, form: string) => (
  dispatch: Dispatch<any>
) =>
  dispatch({
    payload: { values, form },
    type: FORM_VALUES_UPDATED
  })

/**
 *  Dispatch action if user's password reset link is malformed, previously used, or expired
 *
 * @param {function} dispatch   - function used to notify store of updates
 * @param {string} type         - action type
 * @param {string} errorMessage - message to be shown
 * @param {codes} error         - code mappings
 * @returns                     - function
 */
export const handleError = (dispatch: Dispatch<any>, codes: {}) => (
  type: string
) => ({ code = '' }: { code: string | number }, errorMessage?: string) =>
  dispatch({ type, payload: (code && codes[code]) || errorMessage || '' })

/**
 *  Dispatch action to clear any existing errors
 *
 * @returns - void
 */
export const clearExistingErrors = () => (dispatch: Dispatch<any>) =>
  dispatch({
    type: CLEAR_ERROR
  })

/**
 * Dispatch action toggle the open/closed state of the global sidebar component
 *
 * @param {function} dispatch - function used to notify store of updates
 * @returns - void
 */
export const toggleGlobalSidebar = (forceOpenState?: boolean) => (
  dispatch: Dispatch<any>
): void => {
  dispatch({
    payload: forceOpenState,
    type: TOGGLE_GLOBAL_SIDEBAR
  })
}

/**
 * Dispatch action toggle the open/closed state of the mobile filter menu for a dashboard
 *
 * @param {function} dispatch - function used to notify store of updates
 * @returns - void
 */
export const toggleMobileMenu = (forceOpenState?: boolean) => (
  dispatch: Dispatch<any>
): void => {
  dispatch({
    payload: forceOpenState,
    type: TOGGLE_GLOBAL_FILTER_PANE
  })
}

/**
 * Dispatch action toggle if the dashboard has a mobile menu, will show icon and allow user to open/close the menu
 *
 * @param {function} dispatch - function used to notify store of updates
 * @returns - void
 */
export const setDashboardHasFilterPane = (hasMenu: boolean) => (
  dispatch: Dispatch<any>
): void => {
  dispatch({
    payload: hasMenu,
    type: SET_DASHBOARD_HAS_FILTER_PANE
  })
}

/**
 * Dispatch action used for the Universe Component and going up and down the trees. Each path is the index value for each level in the tree
 *
 * @param {function} dispatch - function used to notify store of updates
 * @returns - void
 */
export const setUniverseComponentPaths = (activeListsIndices: number[]) => (
  dispatch: Dispatch<any>
): void => {
  dispatch({
    payload: activeListsIndices,
    type: UNIVERSE_COMPONENT_PATHS_UPDATED
  })
}

/**
 * For every route change, we want to dispatch two actions
 * First action is to update Google Analytics. We're sending to GA the client type, if they are a fanai employee, and the new route
 * Second action is to update the the breadcrumbs
 *
 * @param {string} route
 */
export const routeChangeRequested = (route: string) => (
  dispatch: Dispatch<any>,
  getState: any
): void => {
  dispatch({
    payload: {
      eventData: {
        [analyticsKeys.PAGE]: route
      },
      eventType: PAGE_VIEW
    },
    type: ANALYTICS
  })

  dispatch({
    payload: formBreadcrumbs(route, getState().sdTileState.selectedTile),
    type: 'ROUTE_CHANGE'
  })
}

export const pushRoute = (breadcrumbs: any[]) => (
  dispatch: Dispatch<any>,
  getState: any
): void => {
  dispatch({
    payload: [...getState().appState.breadcrumbs, ...breadcrumbs],
    type: 'ROUTE_CHANGE'
  })
}

/**
 * Action that takes the top level entity and sets that entity in userState.user.client.parentEntity
 *
 * @param {SavedEntity} entity - entity to be saved with the user based on their top level entity id
 * @returns
 */
export const setParentEntity = (entity: SavedEntity) => (
  dispatch: Dispatch<{
    payload: SavedEntity
    type: string
  }>
): void => {
  dispatch({
    payload: entity,
    type: SET_PARENT_ENTITY
  })
}

/**
 * Action that takes the sets the currently selected entity's universe property
 *
 * @param {boolean} isSelected
 * @returns
 */
export const toggleEntityUniverse = (
  keyPath: string | string[],
  universe: boolean
) => (
  dispatch: Dispatch<{
    payload: {
      entity: string | string[]
      universe: boolean
    }
    type: string
  }>
): void => {
  dispatch({
    payload: {
      entity: keyPath,
      universe
    },
    type: SET_ENTITY_UNIVERSE
  })
}

export * from './AuthActions'
