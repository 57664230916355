import { loginUserSuccessSetAnalytics } from 'actions/analyticsActions'
import errorCodes from 'config/errorCodes'
import { login, logout } from 'helpers/auth'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Dispatch } from 'redux'
import { ANALYTICS, CANCELLED_PENDING_REQUESTS } from '../constants'
import { analyticsTypes } from '../constants/analytics'
import {
  LOGGED_OUT,
  LOGIN_REQUESTED,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  UPDATE_SESSION_INFO
} from './types'

/**
 *  Updates state a login has been requested,
 *  calls login helper to being login process,
 *  depending on success/fail, calls necessary
 *  action to dispatch action
 *
 * @export  - loginUser
 * @param {object} obj          - anonymous object
 * @param {string} obj.username - user defined email address
 * @param {string} obj.password - user defined password
 * @returns - function
 */
export const loginUser = ({ username, password }) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: LOGIN_REQUESTED })
    return login(username, password)
  }
}

/**
 *  Dispatch action if user is not authorized
 *
 * @param {function} dispatch
 * @param {string} errorMessage   - returned code from firebase
 * @returns - function
 */
export const loginUserFail = ({
  code,
  message
}: {
  code: string
  message: string
}) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      payload: errorCodes[code] || message,
      type: LOGIN_USER_FAIL
    })
  }
}

/**
 *  Dispatch action if user is authorized
 *
 * @param {function} dispatch
 * @param {string} user   - returned user's UID from firebase
 * @returns - function
 */
export const loginUserSuccess = user => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      payload: {
        ...user
      },
      type: LOGIN_USER_SUCCESS
    })

    dispatch(loginUserSuccessSetAnalytics(user))
  }
}

/**
 *  Logs user out
 *
 * @export  - logoutUser
 * @returns - function
 */
export const logoutUser = () => {
  return (dispatch: Dispatch<any>, getState: any) => {
    dispatch({ type: LOGGED_OUT })
    const { requestCache } = getState().appState

    // if there are current requests in the cache then track that the user signed out before
    // they completed
    dispatch({
      type: CANCELLED_PENDING_REQUESTS
    })
    localStorage.removeItem('token')
  }
}

/**
 *  Store user info from session data
 *
 * @export  - logoutUser
 * @param {object} userData - information taken from session data
 * @returns - function
 */
export const updateUserSession = (userData: {
  username: string
  uid: string
  lastLogin: string
}) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_SESSION_INFO, payload: userData })
  }
}
