import {
  constants,
  FORM_VALUES_UPDATED,
  INITIALIZE_PASSWORD_RECOVERY,
  LOGGED_OUT,
  LOGIN_REQUESTED,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  PASSWORD_RESET_REQUEST_COMPLETE,
  STORE_PASSWORD_RESET_EMAIL,
  UPDATE_CLIENT_DOC,
  UPDATE_SESSION_INFO
} from 'actions/types'
import get from 'lodash/get'
import { User } from 'types'
import { setEntityType } from 'utils/entities'
import { setIsFanaiEmployee } from 'utils/user'
import validate, { isFormValid } from 'utils/validations'
import { USER_PERMISSIONS_RECEIVED } from '../../constants'
import schema from './validationSchema'

const { SET_PARENT_ENTITY } = constants

const initialState = {
  user: {
    login: {
      errorMessage: null,
      fields: {
        email: {
          valid: null,
          value: null
        }
      },
      valid: false
    },
    email: null,
    full_name: null,
    accounts: null,
    is_admin: false,
    id: null,
    token: null
  }
}

export default (state: { user: User } = initialState, action) => {
  switch (action.type) {
    case FORM_VALUES_UPDATED:
      // tslint:disable-next-line
      const {
        payload: { values, form }
      } = action

      // update the input field the user has changed
      // run the new value through the appropriate validator
      const fields = {
        ...state.user[form].fields,
        ...{
          [values.type]: {
            valid: validate(values, schema),
            value: values.value
          }
        }
      }

      return {
        ...state,
        user: {
          ...state.user,
          [form]: {
            ...state.user[form],
            ...{ fields, valid: isFormValid(fields) },
            errorMessage: null
          }
        }
      }

    case LOGIN_USER_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          login: {
            ...state.user.login,
            errorMessage: action.payload,
            valid: false
          }
        }
      }

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }

    case LOGGED_OUT:
      return {
        ...state,
        ...initialState
      }

    case UPDATE_SESSION_INFO:
      const { lastLogin, username, uid } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          lastLogin,
          login: {
            ...state.user.login,
            fields: {
              ...state.user.login.fields,
              username: {
                valid: true,
                value: username
              }
            },
            valid: true
          },
          name:
            state.user && state.user.full_name
              ? state.user.full_name
              : username,
          uid
        }
      }

    case UPDATE_CLIENT_DOC:
      return {
        ...state,
        user: {
          ...state.user,
          client: action.payload
        }
      }

    case SET_PARENT_ENTITY:
      return {
        ...state,
        user: {
          ...state.user
        }
      }

    default:
      return state
  }
}
