import Copy from 'copy/dashboards'
import { RouteComponent, TopLevelRouteComponent } from './index.d'

export default {
  clientmanager: 'Client Manager',
  categoriesAdminManager: 'Categories Manager',
  entitiesadminmanager: 'Public Entities Manager',
  entitycreation: 'Create Entity',
  entityeditor: 'Entity Editor',
  entitysearch: 'Entity Search',
  manageuniverse: 'Manage Universe',
  root: ['/', 'dashboard'],
  sponsorshipmanagement: 'Sponsorship Management',
  sponsorshipsales: 'Sponsorship Sales',
  universemanagement: 'Universe Management'
}

export const dashboardIcons = {
  fanSpendingByBrand: require('!babel-loader!svg-react-loader?name=FanSpendingByBrand!../assets/svg/dashboards/fanSpendingByBrand.svg'),
  fanSpendingByCategory: require('!babel-loader!svg-react-loader?name=FanSpendingByCategory!../assets/svg/dashboards/fanSpendingByCategory.svg')
}

export const sideBarIcons = {
  manageUniverse: require('!babel-loader!svg-react-loader?name=manageUniverseIcon!../assets/svg/summaryDashboard/manageUniverse.svg'),
  myDashboard: require('!babel-loader!svg-react-loader?name=dashboardIcon!../assets/svg/summaryDashboard/myDashboard.svg'),
  sponsorshipSupport: require('!babel-loader!svg-react-loader?name=sponsorshipSupportIcon!../assets/svg/summaryDashboard/sponsorshipSupport.svg')
}

// configuration for the top level routes, these are routes
// that may have one or more sub-routes that can be chosen
// for navigation
export const topLevelRoutes: TopLevelRouteComponent[] = [
  { type: 'myDashboard' },
  { type: 'sponsorshipSupport' },
  { type: 'manageUniverse', isMobileDisabled: true, isRightsholderOnly: true }
].map(r => ({
  ...r,
  copy: Copy.get(r.type),
  icon: sideBarIcons[r.type]
}))

// low-level routes configuration
export const routes: RouteComponent[] = [
  {
    topLevelRoute: 'sponsorshipSupport',
    type: 'fanSpendingByCategory',
    universeComponent: false
  },
  {
    topLevelRoute: 'sponsorshipSupport',
    type: 'fanSpendingByBrand',
    universeComponent: false
  }
].map(r => ({
  ...r,
  copy: Copy.get(r.type),
  icon: dashboardIcons[r.type],
  sidebarIcon: sideBarIcons,
  topLevelRoute: r.topLevelRoute
    ? topLevelRoutes.find(t => t.type === r.topLevelRoute)
    : null
}))

export const appRoutes = {
  categoriesAdminManager: '/categoriesadminmanager',
  categoriesAdminManagerSearch: '/categoriesadminmanager/search',
  categoryData: '/category-data',
  createSpendUpliftReport: '/createSpendupliftReport',
  entitiesAdminManagerEntityEditor: `/entitiesadminmanager/entityeditor`,
  fanSpendByBrand: `/sponsorshipsupport/fanspendingbybrand`,
  fanSpendByCategory: `/sponsorshipsupport/fanspendingbycategory`,
  gdpr: '/gdpr',
  login: '/login',
  emailLogin: '/login/secure-email-link',
  sessionExpired: '/login/sessionExpired',
  myDashboard: '/mydashboard',
  passwordReset: '/login/passwordReset',
  register: '/register',
  registerFailed: '/register/failed',
  registerSuccess: '/register/success',
  sponsorDeepDive: `/sponsorship-deep-dive`,
  sponsorshipSupport: `/sponsorshipsupport`,
  tos: '/tos'
}

export const appRoutesParams = {
  endDate: `end_date`,
  error: 'error',
  propertyTrackingId: 'property_tracking_id',
  startDate: `start_date`,
  propertyId: 'property_id'
}
