import { isEmpty } from 'utils/validations'

/**
 * @member {boolean} isScaled - if true,
 *  expects floating point value between 0±100
 * @member {boolean} convertibleToNumber - if true,
 *  values between 0±0.001 are not converted to ~0, and kept as numbers
 * @member {boolean} includeSign - if true,
 *  positive values will be prefixed with '+'
 */
interface FormatPercentageConfig {
  isScaled?: boolean
  convertibleToNumber?: boolean
  includeSign?: boolean
}
/**
 * Receives a floating point value between 0±1
 * Formats into a percentage value (convertible to number) as specified here:
 * https://app.clubhouse.io/fanai/story/2049/v2-0-app-global-number-formatting
 * Note that the output will be rounded up, not floored.
 *
 * If the percentage value is intended for display,
 * make sure to have config.convertibleToNumber = false
 *
 * If the input number is out of range, caps at ±100%
 * @param {number} percentage - floating point value between 0±1, e.g. 0.123
 * @param {FormatPercentageConfig} config - extra configs,
 *  see [[FormatPercentageConfig]] for more details
 * @returns string - (convertible to number) percentage value, e.g. 12.3.
 */
export const formatPercentage = (
  percentage: number,
  { isScaled, convertibleToNumber, includeSign }: FormatPercentageConfig = {
    isScaled: false,
    convertibleToNumber: false,
    includeSign: false
  }
): string => {
  const signPrefix = percentage > 0 && includeSign ? '+' : ''

  if (percentage === 0) {
    return '0.0'
  } else if (Math.abs(percentage) < (isScaled ? 0.1 : 0.001)) {
    if (!convertibleToNumber) {
      return '~0'
    }
    const scaledPercentage = isScaled ? percentage : percentage * 100
    const output = scaledPercentage.toPrecision(1)
    return `${signPrefix}${output}`
  } else if (Math.abs(percentage) >= (isScaled ? 0.1 : 0.001)) {
    const scaledPercentage = isScaled ? percentage : percentage * 100

    let precision: number
    if (Math.abs(scaledPercentage) < 1) precision = 1
    else if (Math.abs(scaledPercentage) < 10) precision = 2
    else precision = 3

    const output = scaledPercentage.toPrecision(precision)

    return `${signPrefix}${output}`
  }
}

// 1234567 -> 123.4k
//   13456 ->  13.4k
//    1345 ->   1.3k

// 123,500,000,000 -> 123.5b
//  12,500,000,000 ->  12.5b
//   1,230,000,000 ->   1.2b

// 123,500,000 -> 123.5m
//  12,300,000 ->  12.3m
//   1,300,000 ->   1.3m

export const roundToPrecision = (num: number, precision?: number): string => {
  if (Math.abs(num) < 10) {
    return num.toPrecision(precision)
  }

  const value = Math.floor(Math.abs(num)).toString()
  const length = value.length

  let formattedValue

  if (precision > length) {
    formattedValue = `${value}.${Array(precision - length)
      .fill(0, 0, precision - length)
      .join('')}`
  } else if (precision + 1 > length) {
    formattedValue = value
  } else {
    const charArray = getCharArray(value)
    if (length < 4) {
      formattedValue = value
    } else if (length < 7) {
      // thousands
      formattedValue = formatNumber(charArray(2), 'K')
    } else if (length < 10) {
      // millions
      formattedValue = formatNumber(charArray(5), 'M')
    } else if (length < 13) {
      // billions
      formattedValue = formatNumber(charArray(7), 'B', {
        hundredths: true
      })
    }
  }

  const sign = num >= 0 ? '' : '-'
  return `${sign}${formattedValue}`
}

export const round = (num: number | string): string => {
  const parts = num.toString().split('')
  const rounded =
    +parts.pop() > 5
      ? [...parts.slice(0, parts.length - 1), +parts.pop() + 1]
      : parts
  return rounded.join('')
}

export const formatNumber = (
  charArray: string[],
  scale: string,
  { hundredths = false } = {}
) => {
  const roundedNumber = charArray
    .slice(0, charArray.length - (hundredths ? 2 : 1))
    .join('')

  const decimalPlacesSlice = charArray.slice(
    charArray.length - (hundredths ? 2 : 1)
  )

  const decimalPlaces = decimalPlacesSlice.join('')
  let needDot = true

  // removes decimal places for 123K, 123M
  if (roundedNumber.length === 3) {
    needDot = false
  }

  return `${roundedNumber}${needDot ? '.' + decimalPlaces : ''}${scale}`
}

export const getCharArray = (value: string, start: number = 0) => (
  end: number
) => {
  return value.substring(start, value.length - end).split('')
}

/**
 * Receives a number,
 * formats the total number to a readable string with commas
 *
 * @param {number} x       - follower value eg: 1401090
 * @returns string         - ex: 1,401,090
 */
export const formatLongInteger = (x: number | string): string =>
  isEmpty(x) ? '' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

/**
 * Receives a number that represents a dollar,
 * formats the total number to a readable string with commas and dollar sign
 * Will put negative numbers in parens
 *
 * @param {number} x       - follower value eg: 1401090
 * @returns string         - ex: $1,401,090
 */
export const formatLongDollar = (x: number | string): string => {
  if (isEmpty(x)) return ''

  const numString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (x >= 0) return `$${numString}`
  else return `$(${numString.substr(1)})` //strip off the minus first character
}

export const toPrecision = (
  x: number | string,
  precision: number
): string | number => {
  const parts = x.toString().split('.')

  if (parts.length === 1) {
    return typeof x === 'number' ? +parts[0] : parts[0]
  }

  const substring =
    parts[0] === '0'
      ? `${parts[0]}.${parts[1].substring(0, precision)}`
      : `${parts[0]}.${parts[1].substring(0, precision - 1)}`

  return substring
}

/**
 * Receives a number (days),
 * formats to milliseconds
 *
 *
 * @param {number} days  - number of days
 * @returns {number}
 */
export const convertDaysToMilliseconds = days => 24 * 3600 * 1000 * days

/**
 * Function that takes a number, and subtracts that number to todays date
 *
 * @param {number} daysToSubtract     - number value for days to subtract
 * @return string                     - 'YYYY-MM-DD'
 */
export const subtractAndFormatDate = (daysToSubtract: number): string => {
  const today = new Date()
  const formated = today.setDate(today.getDate() - daysToSubtract)
  let month = '' + (new Date(formated).getUTCMonth() + 1)
  let day = '' + new Date(formated).getUTCDate()
  const year = new Date(formated).getUTCFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

/**
 * function that receives a date as a string
 * and returns the UTC value for the provided date
 *
 * @param {string} date       - string value in date form 'YYYY-MM-DD'
 * @returns {number}
 */
export const convertDateStringToUTC = (date: string) => {
  if (!isEmpty(date)) {
    const arr = date.split('-').map(x => Number(x))
    const month = arr[1] - (arr[1] > 0 ? 1 : 0)
    return Date.UTC(arr[0], month, arr[2])
  } else {
    return null
  }
}

/**
 * function used to format a date object to a string
 *  - includes leading 0's
 * @param {Date}
 * @return {string}     - MM-DD-YYYY
 */
export const convertDateToString = (date: Date): string => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }-${year}`
}

/**
 * function that receives a string (eg: 1,000,000) and returns it as a number
 *
 * @param {string} num
 * @returns {number | null}
 */
export const convertNumberAsStringToNumber = (num: string): number | null =>
  num ? parseInt(num.replace(/,/g, ''), 10) : null

/**
 * function used to breakdown follower percentages
 *
 * @param {number} relevantSize
 * @param {number} sharedFollowers
 * @param {number} totalFollowers
 * @param {boolean} isSuperset
 * @returns
 */
export const buildBreakdownValues = (
  relevantSize: number,
  sharedFollowers: number,
  totalFollowers: number,
  isSuperset?: boolean
): {
  nonRelevantPercent: number | string
  relevantEntityPercent: number | string
} => {
  return isSuperset
    ? {
        nonRelevantPercent: toPrecision(
          (sharedFollowers / totalFollowers) * 100,
          2
        ),
        relevantEntityPercent: toPrecision(
          ((totalFollowers - sharedFollowers) / totalFollowers) * 100,
          2
        )
      }
    : relevantSize === sharedFollowers && relevantSize === totalFollowers // perfect overlap
    ? { relevantEntityPercent: 0, nonRelevantPercent: 100 }
    : {
        nonRelevantPercent: toPrecision(
          (sharedFollowers / totalFollowers) * 100,
          2
        ),
        relevantEntityPercent: toPrecision(
          (relevantSize / totalFollowers) * 100,
          2
        )
      }
}

export const isOdd = (value: number): boolean => !!(value % 2)

/**
 * Normalizes user input into a phone number format XXX-XXX-XXX
 * @param {string} value - user input
 * @return {string} input normalized as a phone number
 *
 * @example
 *
 *    normalizePhone('132-4824') returns '132-482-4'
 *    normalizePhone('132-483k') returns '132-483'
 *    (check unit tests for more examples)
 */
export const normalizePhone = (value: string): string => {
  if (!value) return value
  const onlyNums = filterDigitsOnly(value)
  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 6)
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}`
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`
}

/**
 * Returns a string with all non-digit characters removed
 *
 * @param {string} value - string to filter
 */
export const filterDigitsOnly = (value: string): string =>
  value.replace(/[^\d]/g, '')
