import '@babel/polyfill'
import AppContainer from 'pages/app'
import Analytics from 'molecules/analytics'
import React from 'react'
import theme from './theme'
import * as ReactDOM from 'react-dom'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/core'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import 'regenerator-runtime/runtime'
import configureStore from 'stores/configureStore'
import './styles/main.css'

const emotionCache = createCache()
emotionCache.compat = true // disables emotion SSR warnings

const Routes = () => (
  <Provider store={configureStore()}>
    <Router>
      <Analytics>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <AppContainer />
          </ThemeProvider>
        </CacheProvider>
      </Analytics>
    </Router>
  </Provider>
)

ReactDOM.render(<Routes />, document.getElementById('root'))
